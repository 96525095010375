@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

* {
    margin: 0px;
    padding: 0px;
}

.playfair {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.skyblue {
    background-color: #e3f5f9;
}

.gray1 {
    background-color: #efefef;
}

.font2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    line-height: 1.6;
    color: #313131;
    letter-spacing: 1px;
}

.playfair-italic {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-style: italic;
}

.header-ul {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.428571429;
    color: #313131;
    letter-spacing: 0.3px;
}

.bg-style {
    /* background-image: url('https://firebasestorage.googleapis.com/v0/b/iccreators-347d7.appspot.com/o/chess.jpg?alt=media&token=f1b32f05-4077-46a9-95a4-91bcf051afa2'); */
    height: "100vh";
    width: "100%";
    background-repeat: no-repeat;
}

.services-text::before {
    content: "";
    width: 110px;
    height: 65px;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/iccreators-347d7.appspot.com/o/flower-left.png?alt=media&token=fdf3ed37-fb73-499d-b53e-6a821dd75d8d);
    background-size: contain;
    background-position: center left;
    background-repeat: no-repeat;
    position: absolute;
    left: -120px;
    top: 50%;
    transform: translateY(-50%);
}

.services-text::after {
    content: "";
    width: 110px;
    height: 65px;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/iccreators-347d7.appspot.com/o/flower-right.png?alt=media&token=3fe59545-69be-4ac6-8c3d-e69cd9138626);
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    position: absolute;
    right: -120px;
    top: 50%;
    transform: translateY(-50%);
}

.header {
    /* position: absolute;
    top: -10px; */
    width: 100vw;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.1),
        70%,
        rgba(64, 63, 63, 0.419)
    );
    content: "";
}

.point::before {
    content: "✔";
    margin-right: 10px;
    font-family: "FontAwesome" !important;
    color: #613c6d;
    font-size: 18px;
}

@font-face {
    font-family: FontAwesome;
    src: url("./Assets/Fonts/fontawesome.otf") format("truetype");
}

.gallbg {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/iccreators-347d7.appspot.com/o/chess.jpg?alt=media&token=f1b32f05-4077-46a9-95a4-91bcf051afa2");
    height: "100vh";
    width: "100%";
    background-repeat: no-repeat;
}

.login{
    background-image: url('./Assets/bg.jpg');
    background-repeat: no-repeat;
}